<template>
  <div id="home" class="px-2">
    <div class="row">
      <div class="col col-12">
        <h4>
          <small>
            Visualization and analysis of the SARS-CoV-2 mutation patterns in
            the context of the three-dimensional structures of its proteins.
          </small>
        </h4>
        <div>
          For more information, see
          <a href="https://doi.org/10.1093/bioinformatics/btaa550" target="_blank">Sedova et. al</a>
          and
          <a href="https://journals.plos.org/ploscompbiol/article?id=10.1371/journal.pcbi.1009147"
            target="_blank">Jaroszewski et. al.</a>
        </div>
      </div>
    </div>
    <div v-if="statReady" id="statistics" class="text-muted">
      Last update
      <b>{{ lastUpdate || "" }}</b>:
      <span v-if="loading" class="material-icons spinning">hourglass_empty</span>
      <span v-if="!loading">
        {{ allMutations.length }} positions with mutations (based on
        {{ numberGenomes }} genomes),
        {{ structures.features.length }} structures, and
        {{ pdbs.features.length }} models.
      </span>
    </div>

    <div id="staticPreview">
      <div class="loading">Please wait while data is loading...</div>
      <img src="images/main.png" class="loading img-fluid" alt="Static genome view. Please wait..." />
    </div>
    <hr class="less-margin" />
    <div class="row">
      <div class="col-12">
        <form class="form-inline">
          <button type="button" class="btn btn-sm btn-outline-dark" v-on:click="zoomOut2D()" title="Zoom out">
            <span class="material-icons">zoom_out</span>
          </button>
          <button type="button" class="btn btn-sm btn-outline-dark" v-on:click="zoomIn2D()" title="Zoom in">
            <span class="material-icons">zoom_in</span>
          </button>
          <button type="button" class="btn btn-sm btn-outline-dark" v-on:click="zoomFit2D()" title="Zoom to fit">
            <span class="material-icons">zoom_out_map</span>
          </button>
          <button type="button" class="btn btn-sm btn-outline-dark" v-on:click="zoomSelection2D()"
            title="Zoom to selection">
            <span class="material-icons">fullscreen_exit</span>
          </button>
          <span class="side-margin">&#124;</span>
          Selection:
          <input class="form-control form-control-sm" type="text" placeholder="No selection"
            v-bind:value="selectedPositions" readonly />
          <button type="button" class="btn btn-sm btn-outline-dark" v-on:click="zoomFit2D()" title="Clear seletion">
            <span class="material-icons">cached</span>
          </button>
          <span class="side-margin">&#124;</span>
          Coloring:
          <select id="pl-schema-select">
            <option>Original</option>
            <option>Nucleotide</option>
            <option>Purine/pyrimidine</option>
            <option>None</option>
          </select>
          <span class="side-margin">&#124;</span>
          Lineage:
          <select class="form-control" id="currentLineage" name="currentLineage" size="1" v-model="currentLineage"
            @change="setLineage({})">
            <option v-for="value in lineages" :key="value" :value="value">
              {{ value }}
            </option>
          </select>

          <label class="form-check-label small" for="useLineageMutationsChk">
            Use only selected lineage for coloring of 3D structures</label>
          <input class="form-check-input" type="checkbox" value="" id="useLineageMutationsChk"
            v-model="useLineageMutations" />
        </form>
      </div>
    </div>
    <div id="protaelContainer"></div>
    <div class="row alert alert-secondary" v-if="loading">
      <span class="material-icons spinning">hourglass_empty</span> Please wait
      while mutation data is loading...
    </div>
    <ul class="nav nav-tabs" id="infoTabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="struct-tab" data-toggle="tab" href="#struct" role="tab" aria-controls="struct"
          aria-selected="true">3D View</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="proteins-tab" data-toggle="tab" href="#proteinsList" role="tab"
          aria-controls="proteinsList" aria-selected="false">Proteins</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="list-tab" data-toggle="tab" href="#list" role="tab" aria-controls="list"
          aria-selected="false">Structures</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="listTempl-tab" data-toggle="tab" href="#listTempl" role="tab" aria-controls="listTempl"
          aria-selected="false">Models</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="mut-tab" data-toggle="tab" href="#mutlist" role="tab" aria-controls="mutlist"
          aria-selected="false">Nucleotide variants</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="struct" role="tabpanel" aria-labelledby="struct-tab">
        <threeDMolWrapper v-if="selectedPDB != null" v-bind:loading="loading" v-bind:showRandomSelectBox="true"
          v-bind:enablePositionSelection="false" v-bind:mappedPosition="mappedPosition"
          v-bind:clickedResidue="clickedResidue" v-bind:selectedPDB="selectedPDB" v-bind:mutations="
            mutationsInRange(selectedPDB.start, selectedPDB.end)
          " v-bind:mutationCounts="mutationCounts"></threeDMolWrapper>

        <ProteinInfo v-if="selectedProtein" v-bind:protein="selectedProtein" v-bind:structures="
          featuresInRange(
            structures.features,
            selectedProtein.start,
            selectedProtein.end
          )
        " v-bind:models="
  featuresInRange(
    pdbs.features,
    selectedProtein.start,
    selectedProtein.end
  )
"></ProteinInfo>
        <hr />
        <div class="container-fluid" style="margin-top: 20px" v-if="!loading">
          <VariantsTable v-if="selectedPDB != null" v-bind:feature="selectedPDB" v-bind:mutations="
            mutationsInRange(selectedPDB.start, selectedPDB.end)
          "></VariantsTable>
          <VariantsTable v-if="selectedProtein != null" v-bind:feature="selectedProtein" v-bind:mutations="
            mutationsInRange(selectedProtein.start, selectedProtein.end)
          "></VariantsTable>
        </div>
      </div>
      <div class="tab-pane fade" id="proteinsList" role="tabpanel" aria-labelledby="proteins-tab">
        <div id="proteinsDiv">
          <ProteinsTable v-bind:proteins="proteins"></ProteinsTable>
          <!-- <hr />
          <ProteinsTable v-bind:proteins="nspProteins" v-bind:isnsps="true"></ProteinsTable>-->
        </div>
      </div>
      <div class="tab-pane fade" id="list" role="tabpanel" aria-labelledby="list-tab">
        <div id="structList">
          <StructuresTable v-bind:structures="structures.features" v-bind:templates="false"></StructuresTable>
        </div>
      </div>
      <div class="tab-pane fade" id="listTempl" role="tabpanel" aria-labelledby="listTempl-tab">
        <div id="pdbList">
          <StructuresTable v-bind:structures="pdbs.features" v-bind:templates="true"></StructuresTable>
        </div>
      </div>
      <div class="tab-pane fade" id="mutlist" role="tabpanel" aria-labelledby="mut-tab">
        <div id="mutList">
          <VariantsTable v-bind:pdb="null" v-bind:mutations="allMutations"></VariantsTable>
        </div>
      </div>
    </div>

    <hr class="less-margin" />

    <div class="row" style="margin: 40px"></div>
  </div>
</template>

<script>
import Workers from "@/workers";
import Shared from "@/shared/Shared.js";
export default {
  name: "app",
  mixins: [Shared],
  components: {
    threeDMolWrapper: () => import("../components/3DMolWrapper"),
    StructuresTable: () => import("../components/StructuresTable"),
    ProteinInfo: () => import("../components/ProteinInfo"),
    ProteinsTable: () => import("../components/ProteinsTable"),
    VariantsTable: () => import("../components/VariantsTable"),
  },
  data: function () {
    return {
      START: Date.now(),
      //// input files and links:
      fasta: "./static/sequence.fasta", // genbank fasta
      featuresFile: "./static/feature_table.txt",
      modelsTable: "./models/models_.csv",
      structureTable: "./structures/structures.csv",
      proteinsTable: "./proteins/proteins.csv",
      mutationsFile: "./lineages/All_lineages.tsv", //"./static/annotation.tsv", //"
      countFile: "./static/cnbc.vcf.count", // genome count
      dateFile: "./lastupdate",
      lineageFolder: "./lineages",
      lineageListFile: "./lineages/list.txt",
      lineages: [],
      currentLineage: "",
      useLineageMutations: false,
      // constants
      methods: {
        // colors for different methods
        "X-RAY DIFFRACTION": "#0000DD",
        "ELECTRON MICROSCOPY": "#05986d",
        "SOLUTION NMR": "#e90f3e",
        "ALPHAFOLD": "#FFA500"
      },
      //// reactive data
      pdbs: { features: [] }, // original pdbs,
      structures: { features: [] }, // covid-19 pdbs,
      nsps: { features: [] }, // proteins,
      // next one repeats information from nsps, will think about this later
      proteins: [],
      protaelJson: {
        // generated json for protael
        showSequence: false,
        seqcolors: {
          colors: {},
          data: "",
        },
        markers: [],
        bridges: [],
        qtracks: [],
        overlayfeatures: {
          label: "regions",
          showLabels: "true",
          features: [],
        },
        ftracks: [],
      },
      selectedPDB: {
        // selected structure
      },
      selectedProtein: {},
      protael: null, // protael
      lineageMutations: {
        // lineage name: [ mutations ]
        other: [
          // drop this after real lineage data is there
          // AA mutations
          {
            pos: 0,
            count: 0,
            gene: "",
            mtype: "",
            atype: "",
            bchange: "",
            pchange: "",
          },
        ],
      },
      mutations: [
        // AA mutations
        {
          pos: 0,
          count: 0,
          gene: "",
          mtype: "",
          atype: "",
          bchange: "",
          pchange: "",
        },
      ],
      allMutations: [
        // all mutations
        {
          pos: 0,
          count: 0,
          gene: "",
          mtype: "",
          atype: "",
          bchange: "",
          pchange: "",
        },
      ],
      mutationCounts: [],
      avgMutationCounts: [],
      mAtoms: {},
      selectedPositions: "",
      clickedResidue: -1,
      mappedPosition: -1,
      loading: true, //is data still loading?,
      lastUpdate: "",
      numberGenomes: 0,
      statReady: false,
      acceptableChangesRegex: /([ARNDCQEGHILKMFPSTWYV])>([ARNDCQEGHILKMFPSTWYV])/gi, // regex to test to acceptable mutations for avg count charts
    };
  },
  watch: {
    tracksLoaded: function (num) {
      this.log(`Qtracks loaded: ${num}`);
      num === 2 && this.loadLineage("BA.5.merged");
    },
  },
  mounted: function () {
    this.loadSequence(this.fasta);
    this.loadUpdateDate(this.dateFile);

    this.$nextTick(function () {
      this.display();
      this.log("View created");
    });

    this.$nextTick(function () {
      this.loadProteins(this.proteinsTable);
      this.log("Proteins loaded");

      this.loadStructureFromTable(this.structureTable);
      this.log("Structures loaded");

      this.loadModels(this.modelsTable);
      this.log("PDBs loaded");

      this.loadLineageList(this.lineageListFile);

      this.afterInits();
    });

    this.$nextTick(function () {
      this.loadStats(this.countFile);
      this.loadMutationsTsv(this.mutationsFile);
      this.statReady = true;
      this.init3DViewer();
      this.selectRandomPDB();
    });
  },
  computed: {
    tracksLoaded: function () {
      return this.protael ? this.protael.protein.qtracks.length : 0;
    },
  },
  methods: {
    mutationsInRange(start, end) {
      if (this.useLineageMutations && this.lineageMutations[this.currentLineage]) {
        return this.lineageMutations[this.currentLineage].filter((m) => {
          return m.variant >= start && m.variant <= end;
        });
      }
      return this.mutations.filter((m) => {
        return m.variant >= start && m.variant <= end;
      });

    },
    featuresInRange(featurelist, start, end) {
      // returns features overlapping with given range
      return featurelist.filter((f) => {
        return (
          (f.start >= start && f.start <= end) ||
          (f.end >= start && f.end <= end)
        );
      });
    },
    log: function (msg) {
      const t = ((Date.now() - this.START) / 1000).toFixed(2);
      // eslint-disable-next-line no-console
      console.log(`${t}ms: ${msg}`);
    },
    //// DATA LOADING
    loadUpdateDate(url) {
      let self = this;
      window.jQuery.ajax({
        url: url,
        success: function (results) {
          self.lastUpdate = results.split("\n")[0].trim();
        },
        async: true,
      });
    },
    loadSequence(url) {
      let self = this;
      window.jQuery.ajax({
        url: url,
        success: function (results) {
          let lines = results.split("\n");
          let seq = "";
          for (let i = 1; i < lines.length; i++) seq += lines[i].trim();

          self.protaelJson.sequence = seq;
        },
        async: false,
      });
    },
    loadGenes(url) {
      let self = this;

      window.jQuery.ajax({
        url: url,
        success: function (results) {
          self.protael.addFTrack(results);
        },
        async: false,
      });
    },
    loadProteins(proteinsUrl) {
      let self = this;
      let track = {
        label: "Proteins",
        allowOverlap: false,
        showLine: true,
        display: "line",
        color: "#e90f3e",
        features: [],
        minGap: 400,
      };

      function parseResults(results) {
        let lines = results.split("\n");
        lines.forEach((l) => {
          if (!l.startsWith("#")) {
            let cols = l.split(",");

            let p, q;

            // 0       1        2             3         4                                    5                     6
            // #Name,Label,Genomic Start,Genomic End,Function (based on literature),C-terminal cleavage site,orf1ab start-end

            if (cols.length < 6) {
              self.log(`Unparsable line (not enough columns${l}`);
            } else {
              q = {
                name: cols[0].trim(),
                id: "protein_" + cols[1].trim(),
                label: cols[1].trim(),
                start: parseInt(cols[2].trim()),
                end: parseInt(cols[3].trim()),
                funct: cols[4].trim(),
                clevsite: cols[5].trim(),
                orf1abloc: cols[6].trim(),
                type: "protein", // for display switch
              };

              p = {
                label: q.label,
                id: q.id,
                start: q.start,
                end: q.end,
                color: "#e90f3e",
                clazz: "protein-feature",
                properties: {
                  Name: q.name.replace("~", "&apos;"),
                  "Putative function": q.funct.replace("~", "&apos;"),
                  "Genomic start-end": q.start + " - " + q.end,
                  "C-terminal cleavage site": q.clevsite.replace("~", "&apos;"),
                },
              };
              if (q.orf1abloc != "n/a") p["orf1ab start-end"] = q.orf1abloc;

              if (q.label === "orf1ab") {
                p.color = "orange";
                p.properties["Description"] = "Boundaries of the orf1ab gene";
              }
            }

            self.proteins.push(q);
            track.features.push(p);
          }
        });
      }

      window.jQuery.ajax({
        url: proteinsUrl,
        success: function (results) {
          parseResults(results);
        },
        async: false,
      });

      self.nsps = track;

      self.log("Adding proteins");

      self.protael.addFTrack(self.nsps);
    },
    loadLineageList(listUrl) {
      let self = this;
      window.d3.text(listUrl).then(function (text) {
        let lines = text.split("\n");
        lines.forEach((l) => {
          if (!l.startsWith("#")) {
            self.lineages.push(l.trim());
          }
        });
      });
    },
    async loadMutationsTsv(url) {
      const self = this;
      window.jQuery("body").addClass("waiting");

      const startTime = Date.now();
      const acceptableChangesRegex = /([ARNDCQEGHILKMFPSTWYV])>([ARNDCQEGHILKMFPSTWYV])/gi; // regex to test to acceptable mutations for avg count charts

      // TODO: check performance of d3.tsv vs simple parsing
      window.d3
        .tsv(url, function (d) {
          // let cnt = d.aaMutationCount;

          const cnt = (d["AA change"].match(acceptableChangesRegex) || [])
            .length;

          let m = {
            variant: +d["Genome position"], //+d.variant,
            refRegion: d["Gene/Protein name"], //d.refRegion,
            mutationNumber: +d["Virus count for missense mutations"], //+d.mutationNumber,
            mutationType: d["Mutation types"], //d.mutationType,
            annotationType: d["Annotation types"], //d.annotationType,
            alt: d["Base change:Virus count"], //d.alt,
            proteinAminoAcids: d["AA change"], //d.proteinAminoAcids,

            aaMutationCount: +cnt || 0,
          };
          return m;
        })
        .then(function (data) {
          const elaspedTime = Date.now() - startTime;
          self.log(`Mutations downloaded after ${elaspedTime}ms`);
          self.allMutations = data;

          const nonSynMutations = data.filter(
            (m) => m.proteinAminoAcids.indexOf(">") > 0
          );

          self.mutations = Object.freeze(nonSynMutations);
          self.loading = false;

          // use for coloring
          self.protael.addQTrack(
            self.mutationsToChart(
              self.mutations,
              "Mutations",
              "Virus counts of protein mutations"
            )
          );

          // array with mut.count for each position

          self.mutationCounts = new Array(self.protaelJson.sequence.length);
          self.mutationCounts.fill(0);
          self.mutations.forEach(
            (m) => (self.mutationCounts[m.variant - 1] = m.aaMutationCount)
          );

          const win = 100;
          // next function calls worker to generate qtrack
          self.addAvgMutationsChart(
            self.mutationCounts,
            "MutRate",
            `Rate of protein mutations (${win}nt average)`,
            win
          );

          self.log("Mutations chart created");
          self.afterChartInit();
        })
        .finally(function () {
          window.jQuery("body").removeClass("waiting");
        });
    },
    async loadLineage(title) {
      const self = this;
      window.jQuery("body").addClass("waiting");
      const startTime = Date.now();
      window.d3
        .tsv(self.lineageFolder + "/" + title + ".tsv", function (d) {
          let m = {
            variant: +d["Genome position"], //+d.variant,
            refRegion: d["Gene/Protein name"], //d.refRegion,
            mutationNumber: +d["Virus count for missense mutations"], //+d.mutationNumber,
            mutationType: d["Mutation types"], //d.mutationType,
            annotationType: d["Annotation types"], //d.annotationType,
            alt: d["Base change:Virus count"], //d.alt,
            proteinAminoAcids: d["AA change"] || "", //d.proteinAminoAcids,
          };
          // find all ";" chars, and add 1 to count
          m.aaMutationCount =
            (m.proteinAminoAcids.match(/;/g) || []).length + 1;
          return m;
        })
        .then(function (data) {
          const elaspedTime = Date.now() - startTime;
          self.log(`Lineage data downloaded after ${elaspedTime}ms`);
          self.lineageMutations.other && delete self.lineageMutations.other;
          self.lineageMutations[title] = data;
          self.protael.updateQTrack(
            "lineageMutations",
            self.mutationsToChart(
              data,
              "lineageMutations", //title,
              `Virus counts of protein mutations  @${title}`,
              "#006644"
            )
          );

          self.afterChartInit();
        })
        .finally(function () {
          window.jQuery("body").removeClass("waiting");
        });
    },
    loadModels(url) {
      let self = this;

      // this.log("Parsing PDBs data...");

      let track = {
        label: "Models",
        allowOverlap: false,
        showLine: true,
        display: "line",
        color: "#e90f3e",
        features: [],
      };
      window.jQuery.ajax({
        url: url,
        success: function (results) {
          let lines = results.split("\n");
          lines.forEach((l) => {
            if (!l.startsWith("#")) {
              let cols = l.split(",");
              let pdbid = cols[0].trim();

              if (cols.length > 6) {
                let p = {
                  //id start end seqid ali name method url
                  label: pdbid,
                  start: parseInt(cols[1].trim()),
                  end: parseInt(cols[2].trim()),
                  color: "#e90f3e",
                  clazz: "pdb-feature",
                  id: "pdb_" + pdbid, //.substring(0, 4),
                  chain: pdbid.substring(4), // make sure we have only single-letter chains!
                  url:
                    "./models/" + pdbid.substring(0, 4).toUpperCase() + ".pdb",
                  isModel: true,
                  type: "pdb",
                  properties: {
                    Title: cols[5].trim(),
                    pdbid: cols[6].trim() === "ALPHAFOLD" ? "" : pdbid.substring(0, 4),
                    "PDB structure": pdbid,
                    Method: cols[6].trim(),
                    "Sequence identity": parseInt(cols[3].trim()) + "%",
                    alignment_start: parseInt(cols[4].trim()),
                  },
                };
                let color = self.methods[p.properties.Method];
                color && (p.color = color);

                track.features.push(p);
              }
            }
          });
          self.pdbs = track;

          self.log("Adding models");
          self.protael.addFTrack(self.pdbs);
        },
        async: false,
      });

      // this.log("Parsed.");
    },
    loadStructureFromTable(url) {
      let self = this;

      let track = {
        label: "Structures",
        allowOverlap: false,
        showLine: true,
        display: "line",
        color: "#e90f3e",
        features: [],
        minGap: 600,
      };
      window.jQuery.ajax({
        url: url,
        success: function (results) {
          let lines = results.split("\n");
          lines.forEach((l) => {
            if (!l.startsWith("#")) {
              let cols = l.split(",");
              if (cols.length > 4) {
                const pdbid = cols[0].trim();
                const pdbCode = pdbid.substring(0, 4);

                let p = {
                  label: pdbid,
                  start: parseInt(cols[1].trim()),
                  end: parseInt(cols[2].trim()),
                  color: "#e90f3e",
                  clazz: "structure-feature",
                  id: "struct_" + pdbid,
                  chain: pdbid.substring(4), // make sure we have only single-letter chains!
                  isModel: false,
                  url: "./structures/" + pdbCode.toUpperCase() + ".pdb",
                  isCSGID: cols[6].trim() === "true",
                  type: "pdb",
                  properties: {
                    Title: cols[4].trim(),
                    pdbid: pdbCode,
                    "PDB structure": pdbid,
                    Method: cols[5].trim(),
                    alignment_start: parseInt(cols[3].trim()),
                  },
                };

                if (p.isCSGID) {
                  p.properties.comment = "Solved by CSGID";
                  p.clazz = "structure-feature csgid";
                }

                let color = self.methods[p.properties.Method];
                color && (p.color = color);

                track.features.push(p);
              }
            }
          });
          self.structures = track;
          self.log("Adding structures");
          self.protael.addFTrack(self.structures);

          const marker =
            "<tspan dy='-8px' dx='-2px' class='marks'>CSGID</tspan>";

          window
            .jQuery("rect.csgid")
            .siblings("text")
            .each(function () {
              let me = window.jQuery(this);
              me.html(me.html() + marker);
            });
        },
        async: false,
      });

      // this.log("Parsed.");
    },
    async loadStats(url) {
      let self = this;
      window.jQuery.ajax({
        url: url,
        success: function (results) {
          if (!results) self.numberGenomes = "n/a";
          self.numberGenomes = results.trim();
        },
        async: true,
      });
    },

    //// DISPLAY
    setLineage() {
      this.currentLineage && this.loadLineage(this.currentLineage);
    },

    mutationsToChart(mutations, id, label, color = "blue", scale = "log10") {
      const l = this.protaelJson.sequence.length;
      // let max = 0;
      let qtrack = {
        id: id,
        label: label,
        color: color,
        type: "bar",
        values: new Array(l),
        transform: scale,
        forceNonZero: true,
        clazz: "shadow c3d-mutations",
        tooltipPrefix: "Count",
      };
      qtrack.label += " [" + scale + " scale]";
      qtrack.values.fill(0);
      mutations &&
        mutations.forEach((m) => {
          qtrack.values[m.variant - 1] = m.mutationNumber;
        });

      return qtrack;
    },
    /**
     * Calculate slideng average over data, create qtrack
     */
    async addAvgMutationsChart(
      mutations,
      id,
      label,
      requestedWindow,
      color = "#f2072e"
    ) {
      if (!mutations || mutations.length === 0) {
        this.log(`No data provided for averaging`);
        return;
      }
      const self = this;
      requestedWindow = requestedWindow || 100;

      let qtrack = {
        id: id,
        label: label,
        color: color,
        type: "bar",
        values: new Array(this.protaelJson.sequence.length),
        // transform: "log10",
        forceNonZero: true,
        clazz: "shadow c3d-mutations-avg",
        tooltipPrefix: "Rate",
      };

      Workers.qtrackWorker.onmessage = (event) => {
        // self.log(event.data);
        qtrack = event.data;
        self.avgMutationCounts = [...qtrack.values];
        self.protael.updateQTrack(id, qtrack);
        self.log("AvgMutations chart ready");
      };
      Workers.qtrackWorker.postMessage({
        data: mutations,
        qtrack: qtrack,
        win: requestedWindow,
      });
    },
    async display() {
      let self = this;
      // eslint-disable-next-line no-unused-vars
      let Snap = window.Snap;
      // eslint-disable-next-line no-unused-vars
      Snap.plugin(function (Snap, Element, Paper, glob) {
        // eslint-disable-next-line no-unused-vars
        Element.prototype.dragVertical = function () { };
      });

      window.jQuery("#staticPreview").remove();

      // eslint-disable-next-line no-unused-vars
      this.protael = window.Protael(
        this.protaelJson,
        "protaelContainer",
        false
      );
      this.protael.onSelectionChange(function () {
        self.selectedPositions = self.protael.getSelection().join(":");
      });

      this.protael.onSelectionEnd(function () {
        const sel = self.protael.getSelection();
        if (sel[0] === sel[1]) return;
        self.protael.zoomToSelection();
      });

      this.protael.draw();

      // have to call it after draw()
      this.protael.onClick(function (e) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        const x = self.protael.toOriginalX(self.protael.mouseToSvgXY(e).x);

        self.clickedResidue = x;
        self.selectPDBAt(x);
        self.selectedPositions = self.protael.getSelection().join(":");
        self.labelResidue(x);
      });
    },
    async afterInits() {
      let self = this;
      window
        .jQuery(".pl-feature.pdb-feature")
        .mousedown(function (event) {
          event.stopImmediatePropagation();
          event.stopPropagation();
        })
        .click(function (event) {
          event.stopImmediatePropagation();
          event.stopPropagation();
          let id = window.jQuery(this).attr("id");
          self.selectPDB(id, 1);
        });
      window
        .jQuery(".pl-feature.structure-feature")
        .mousedown(function (event) {
          event.stopImmediatePropagation();
          event.stopPropagation();
        })
        .click(function (event) {
          event.stopImmediatePropagation();
          event.stopPropagation();
          let id = window.jQuery(this).attr("id");
          self.selectPDB(id, 2);
        });
      window
        .jQuery(".pl-feature.protein-feature")
        .mousedown(function (event) {
          event.stopImmediatePropagation();
          event.stopPropagation();
        })
        .click(function (event) {
          event.stopImmediatePropagation();
          event.stopPropagation();
          let id = window.jQuery(this).attr("id");
          self.selectProtein(id);
        });
      window.jQuery("#pl-schema-select").change(function () {
        self.protael.setColoringScheme(window.jQuery(this).val());
      });
    },

    afterChartInit() {
      const self = this;
      window.jQuery(".c3d-mutations").mousemove(function (e) {
        let chart = window.jQuery(this);
        const pos = self.protael.toOriginalX(self.protael.mouseToSvgXY(e).x);
        const mutation = self.selectMutationAt(pos);
        let datad = chart.is("[data-tooltip]")
          ? JSON.parse(chart.attr("data-tooltip"))
          : {};
        datad["Position"] = pos;
        datad["Virus count"] = mutation ? mutation.mutationNumber : "0";
        datad["Mutations"] = mutation ? mutation.alt : "0";
        datad["AA changes"] = mutation
          ? mutation.proteinAminoAcids
          : "No changes";
        chart.attr({ "data-tooltip": JSON.stringify(datad) });
      });
      // window.jQuery(".c3d-mutations-avg").mousemove(function (e) {
      //   let chart = window.jQuery(this);
      //   const pos = self.protael.toOriginalX(self.protael.mouseToSvgXY(e).x);
      //   const mutation = self.selectMutationAt(pos);
      //   let datad = chart.is("[data-tooltip]")
      //     ? JSON.parse(chart.attr("data-tooltip"))
      //     : {};
      //   datad["Position"] = pos;
      //   datad["Rate"] = mutation ? mutation.mutationNumber : "0";
      //   datad["Mutations"] = mutation ? mutation.alt : "0";
      //   datad["AA changes"] = mutation
      //     ? mutation.proteinAminoAcids
      //     : "No changes";
      //   chart.attr({ "data-tooltip": JSON.stringify(datad) });
      // });
    },
    //// SELECTION
    clearSelection() {
      // remove selection
      let current;
      if (this.selectedPDB && this.selectedPDB.id) {
        current = window.Snap.select("#" + this.selectedPDB.id);
      } else if (this.selectedProtein && this.selectedProtein.id) {
        current = window.Snap.select("#" + this.selectedProtein.id);
      }
      current && current.removeClass("selected");
      // this.$emit("selection_clear");
      this.selectedPDB = null;
      this.selectedProtein = null;
    },
    selectProtein(id) {
      // when user clicks on protein feature
      this.clearSelection();
      this.log("Selecting: " + id);

      let list = this.proteins.filter((p) => p.id === id);

      if (list && list.length) this.selectedProtein = list[0];

      if (!this.selectedProtein) return;

      window.Snap.select("#" + id).addClass("selected");
    },
    selectPDB(pdbid, idType) {
      // when user clicks on structure or model
      this.clearSelection();
      this.log("Selecting: " + pdbid);

      let pdbs =
        idType === 1
          ? this.pdbs.features.filter((p) => p.id === pdbid)
          : this.structures.features.filter((p) => p.id === pdbid);

      if (pdbs && pdbs.length) this.selectedPDB = pdbs[0];

      if (!this.selectedPDB) return;

      window.Snap.select("#" + pdbid).addClass("selected");
    },
    selectRandomPDB() {
      const pdbs = this.structures.features;
      if (pdbs && pdbs.length) {
        const i = Math.floor(Math.random() * pdbs.length);
        this.selectPDB(pdbs[i].id, 2);
      }
    },
    selectPDBAt(pos) {
      if (
        this.selectedPDB &&
        this.selectedPDB.start <= pos &&
        this.selectedPDB.end >= pos
      ) {
        return;
      }
      if (
        this.selectedProtein &&
        this.selectedProtein.start <= pos &&
        this.selectedProtein.end >= pos
      ) {
        return;
      }

      const compare = function (a, b) {
        return a.start > b.start;
      };
      const filt = function (s) {
        return s.start <= pos && s.end >= pos;
      };

      let list = this.structures.features.filter(filt).sort(compare);
      if (list.length > 0) {
        this.selectPDB(list[0].id, 2);
        return;
      }

      list = this.pdbs.features.filter(filt).sort(compare);
      if (list.length > 0) {
        this.selectPDB(list[0].id, 1);
        return;
      }

      list = this.nsps.features.filter(filt).sort(compare);
      if (list.length > 0) {
        let id = list[0].id;
        // (in case of orf1ab priority goes to smaller proteins)
        if (id === "protein_orf1ab" && list.length > 1) id = list[1].id;
        this.selectProtein(id, 1);
        return;
      }
    },
    selectMutationAt(pos) {
      const list = this.mutations.filter((m) => m.variant === pos);
      return list[0];
    },
    hightlightTableRow(x, dx = 0) {
      window
        .jQuery(".table-variants tr.table-warning")
        .removeClass("table-warning");
      window.jQuery(".table-variants tr." + x).addClass("table-warning");
      if (dx > 0) {
        // if mutation is in codon, but not in the exact position:
        window
          .jQuery(".table-variants tr." + (x + 1))
          .addClass("table-warning");
        window
          .jQuery(".table-variants tr." + (x + 2))
          .addClass("table-warning");
      }
    },
    labelResidue(x, dx = 0) {
      if (x < 0) return;
      if (this.selectedProtein) {
        this.hightlightTableRow(x, dx);
        return;
      }

      if (this.selectedPDB) {
        const s = this.selectedPDB.start;
        const e = this.selectedPDB.end;
        const f = this.selectedPDB.properties.alignment_start;

        this.mappedPosition = this.dna2prot(x, s, e, f);

        if (this.mappedPosition && this.mappedPosition > 0) {
          this.hightlightTableRow(x, dx);
        }
      }
    },
    zoomTo(pos) {
      this.protael.setSelection(pos, pos);
      this.protael.zoomToSelection();
      this.protael.setSelection(pos, pos);
      this.labelResidue(pos);
    },
    zoomToCodon(pos) {
      this.protael.setSelection(pos, pos + 2);
      this.protael.zoomToSelection();
      this.protael.setSelection(pos, pos + 2);
      this.labelResidue(pos, 1);
    },
    zoomOut2D() {
      this.protael && this.protael.zoomOut();
    },
    zoomIn2D() {
      this.protael && this.protael.zoomIn();
    },
    zoomFit2D() {
      this.protael && this.protael.zoomToFit();
    },
    zoomSelection2D() {
      this.protael && this.protael.zoomToSelection();
    },
    clearSelection2D() {
      this.protael && this.protael.clearSelection();
    },
    ////// 3D only
    async init3DViewer() {
      const self = this;
      const filtered = self.structures.features.filter((s) => s.isCSGID);
      const i = Math.floor(Math.random() * filtered.length);
      const pdb = filtered[i].id;
      self.selectPDB(pdb, 2);
    },
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
  margin-top: 0px;
}

body.waiting * {
  cursor: progress !important;
}

.pl-feature-label {
  font-size: 12px;
  text-anchor: middle;
}

.pl-feature.selected rect {
  stroke: magenta;
  stroke-width: 10px;
}

.selected-pdb {
  border-bottom: 6px magenta solid;
}

#structureView {
  /* width: 100%;
  height: 100%; */
  position: relative;
}

svg .pl-ftrack-label {
  font-size: 18px;
}

svg .pl-chart-label {
  font-size: 18px;
}

.marks {
  font-size: 6px;
  fill: blue;
  /* baseline-shift: super; */
}

hr.less-margin {
  margin-top: 0.2 rem;
  margin-bottom: 0.1rem;
}

button.btn.btn-sm {
  padding: 4px 5px 0px 5px;
  margin-left: 2px;
}

.spinning {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
</style>
